<template>
  <my-header></my-header>
  <section class="personal-wrap" ref="parent">
    <!-- <div class="return-btn">
      <img src="@/assets/images/self-return.png" @click="$router.back()" alt="" />
      个人中心
    </div> -->
    <a-button @click="goBack" class="back-btn">返回</a-button>
    <section class="info-box">
      <section class="avatar">
        <img class="img" :src="avatar || require('@/assets/images/default-img.png')" alt="" />
        <!-- <span class="edit-text" @click="exchange">修改头像</span> -->
        <!-- <a-upload
          v-model:file-list="fileList"
          name="file"
          withCredentials
          accept="webp,bmp,jpg,png,tif,gif"
          :action="action"
          :headers="{
            authorization: 'authorization-text',
          }"
          :before-upload="beforeUpload"
          @change="editAvatar"
        >
          <span class="edit-text">编辑</span>
        </a-upload> -->
      </section>
      <section class="uploadbox-center">
        <a-button @click="exchange">
          <template #icon><UploadOutlined /></template>
          更换头像
        </a-button>
      </section>
      <section class="info-content">
        <div class="edit-item">
          <span class="label">姓名</span>
          <span class="content">{{ userName }}</span>
        </div>
        <div class="edit-item">
          <span class="label">账号</span>
          <span class="content">{{ mobile }}</span>
          <span @click="openModal('account')" class="text">更换账号</span>
        </div>
        <div class="edit-item">
          <span class="label">登录密码</span>
          <!-- <span class="content">{{ isNotInitPassword ? '未设置' : '******' }}</span> -->
          <span class="content">********</span>
          <!-- <span @click="openModal(isNotInitPassword ? 'repassword' : 'password')" class="text">{{
            isNotInitPassword ? '设置' : '修改'
          }}</span> -->
          <!-- <span @click="openModal(isNotInitPassword ? 'repassword' : 'password')" class="text">修改</span> -->
        </div>
      </section>
    </section>
  </section>
  <edit-user-modal v-model:visible="visible" :type="type" :mobile="mobile" :accountType="accountType" />
  <WrapModal
    :width="width"
    :tooSmall="true"
    v-model:visible="avatarVisible"
    :modealTitle="modealTitle"
    :maskClosable="true"
    :okText="okText"
    :cancelText="cancelText"
    :confirmLoading="confirmLoading"
    @ok="submit"
    @cancel="cancel"
  >
    <section>
      <a-row class="avatar-content" justify="space-between" align="top">
        <a-col class="avatar-col">
          <div class="cropper" ref="cropperRef">
            <vue-picture-cropper
              :boxStyle="{
                width: '100%',
                height: '100%',
                backgroundColor: '#f8f8f8',
                margin: 'auto'
              }"
              :img="pic ? pic : require('@/assets/images/default-img.png')"
              :options="{
                viewMode: 1,
                dragMode: 'move',
                aspectRatio: 16 / 16,
                guides: false,
                cropBoxMovable: true,
                autoCropArea: 0.9,
                canMove: false,
                canMoveBox: true,
                autoCrop: true // 是否默认生成截图框
              }"
              @cropend="getResult"
              @zoom="getResult"
              @ready="ready"
            />
          </div>
          <a-row class="upload-content" justify="space-between">
            <a-col>
              <a-button class="select-picture">
                <span style="color: #c3161c; cursor: pointer">重新上传</span>
                <input
                  ref="uploadInput"
                  type="file"
                  accept="image/jpg, image/jpeg, image/png, image/gif"
                  @change="selectFile"
                  style="cursor: pointer; font-size: 0"
                />
              </a-button>
            </a-col>
            <a-col style="float: right">
              <i class="iconfont icon-fangda icons" @click="changeAvatar('enlarge')"></i>
              <i class="iconfont icon-suoxiao icons" @click="changeAvatar('narrow')"></i>
              <i class="iconfont icon-xuanzhuan icons" @click="changeAvatar('rotateRight')"></i>
            </a-col>
          </a-row>
        </a-col>
        <a-col style="text-align: center">
          <a-avatar
            :size="104"
            :src="result ? result : require('@/assets/images/default-img.png')"
            alt=""
            style="margin-bottom: 8px"
          />
          <p style="color: #666666">头像预览</p>
        </a-col>
      </a-row>
    </section>
  </WrapModal>
</template>

<script>
import { computed, defineComponent, reactive, toRefs, ref, watch, onMounted, watchEffect, createVNode } from 'vue'
import VuePictureCropper, { cropper } from 'vue-picture-cropper'
import { useStore } from 'vuex'
import { SET_USER_INFO } from '@/store/user/mutations-type'
import { editAccountAvatar, exchangeAvatar } from '@/apis/user'
import { useRouter } from 'vue-router'
import { cmsNotice } from '@/utils/utils'
const action = process.env.VUE_APP_API_BASE_URL + '/file/upload/one'
import EditUserModal from './components/modal.vue'
import myHeader from '@/components/Header/index'
import WrapModal from '@/components/WrapModal/index'
import { Modal } from 'ant-design-vue'
import { ExclamationOutlined, UploadOutlined } from '@ant-design/icons-vue'
export default defineComponent({
  name: 'personalCenter',
  components: {
    EditUserModal,
    myHeader,
    WrapModal,
    VuePictureCropper,
    UploadOutlined
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const uploadInput = (ref < HTMLInputElement) | (null > null)
    const cropperRef = ref(null)
    const pic = ref('')
    const result = ref('')
    const state = reactive({
      visible: false,
      avatarVisible: false,
      type: 'account',
      fileList: [],
      modealTitle: '上传头像',
      okText: '修改',
      cancelText: '取消',
      confirmLoading: false,
      width: '478px',
      fileName: '',
      navData: computed(() => store.state.router.navMenus)
    })
    const userInfo = computed(() => store.state.user.userInfo)
    console.log(userInfo.value)
    if (!store.state.user.userInfo.userName)
      store.commit(`user/${SET_USER_INFO}`, JSON.parse(localStorage.getItem('yda-admin-userInfo')))
    const mobile = computed(() => store.state.user.userInfo.result.account)
    const accountType = computed(() => store.state.user.userInfo.result.accountType)
    const avatar = computed(() => store.state.user.userInfo.avatar)
    const userName = userInfo.value.userName
    const isNotInitPassword = computed(() => store.state.user.userInfo.isNotInitPassword)
    const openModal = type => {
      if (type === 'account') {
        //更改账号确认提示框
        Modal.confirm({
          title: '是否更换账号',
          centered: true,
          width: '480px',
          icon: createVNode(ExclamationOutlined),
          content: '当前账号企业信息会一键转移并同步至新账号！',
          onOk() {
            // 确定之后   打开修改手机号修改
            state.type = type
            state.visible = true
          }
        })
      } else {
        state.type = type
        state.visible = true
      }
    }
    const beforeUpload = file => {
      return new Promise((resolve, reject) => {
        const type = file.name.split('.').pop()
        const fileType = ['webp', 'bmp', 'jpg', 'png', 'tif', 'gif']
        if (!fileType.includes(type)) {
          cmsNotice('error', `请上传${fileType}格式文件`)
          return reject(false)
        }
        return resolve(true)
      })
    }
    const editAvatar = async info => {
      const {
        file: { status, response }
      } = info
      switch (status) {
        case 'uploading':
          break
        case 'done':
          // 处理上传成功
          if (response && response.code === 0) {
            console.log(response)
            const res = await editAccountAvatar({ avatarId: response.data.id })
            if (res.success) {
              cmsNotice('success', '上传成功')
              const userInfo = JSON.parse(localStorage.getItem('yda-admin-userInfo'))
              userInfo.avatar = response.data.fullUrl
              localStorage.setItem('yda-admin-userInfo', JSON.stringify(userInfo))
              store.commit(`user/${SET_USER_INFO}`, userInfo)
            }
          }
          break
      }
    }

    const exchange = () => {
      state.avatarVisible = true
      pic.value = store.state.user.userInfo.avatar
      result.value = store.state.user.userInfo.avatar
    }
    const cancel = () => {
      pic.value = ''
      result.value = ''
      state.fileName = ''
      state.avatarVisible = false
      cropper.clear()
    }
    const submit = async () => {
      state.confirmLoading = true
      if (!result.value) {
        cmsNotice('error', '请上传图片')
        state.confirmLoading = false
        return
      }
      const response = await exchangeAvatar({ base64: result.value, fileName: state.fileName })
      if (response && response.code === 0) {
        const res = await editAccountAvatar({ avatarId: response.data.id })
        if (res.success) {
          cmsNotice('success', '上传成功')
          const userInfo = JSON.parse(localStorage.getItem('yda-admin-userInfo'))
          userInfo.avatar = response.data.fullUrl
          localStorage.setItem('yda-admin-userInfo', JSON.stringify(userInfo))
          store.commit(`user/${SET_USER_INFO}`, userInfo)
          cancel()
        }
        state.confirmLoading = false
      }
    }
    /**
     * 选择图片
     */
    const selectFile = e => {
      // 重置上一次的结果
      result.value = ''
      getResult()
      // 获取选取的文件
      const target = e.target
      const { files } = target
      // 目前支持jpg，jpeg，png，git
      if (!files) return
      const file = files[0]
      const type = files[0].name.split('.').pop()
      // const fileType = ['webp', 'bmp', 'jpg', 'png', 'tif', 'gif']
      const fileType = ['jpg', 'png', 'gif', 'jpeg']
      if (!fileType.includes(type)) {
        cmsNotice('error', `请上传${fileType}格式文件`)
        return
      }
      state.fileName = files[0].name
      // 转换为base64传给裁切组件
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        // 更新裁切弹窗的图片源
        pic.value = String(reader.result)
        result.value = String(reader.result)
        // 清空已选择的文件
        if (!uploadInput.value) return
        uploadInput.value.value = ''
      }
    }

    const ready = data => {
      if (pic.value) {
        getResult()
      }
    }

    /**
     * 获取裁切结果
     */
    const getResult = () => {
      // 获取生成的base64图片地址
      const base64 = cropper.getDataURL()
      // 获取生成的blob文件信息
      const blob = cropper.getBlob()
      // console.log({ base64, blob })
      // 把base64赋给结果展示区
      result.value = base64
    }

    function changeAvatar(type) {
      switch (type) {
        case 'rotateRight':
          cropper.rotate(90)
          break
        case 'narrow':
          cropper.zoom(-0.1)
          break
        case 'enlarge':
          cropper.zoom(0.1)
          break
        default:
          break
      }
      getResult()
    }
    // const rotateRight = () => {
    //   cropper.rotate(90)
    //   getResult()
    // }
    // const narrow = () => {
    //   cropper.zoom(-0.1)
    //   getResult()
    // }
    // const enlarge = () => {
    //   cropper.zoom(0.1)
    //   getResult()
    // }
    const goBack = () => {
      let rdRouter
      if (state.navData[0]) {
        if (state.navData[0].children && state.navData[0].children.length) {
          rdRouter = state.navData[0].children[0].path
        } else {
          rdRouter = state.navData[0].path
        }
        router.replace(rdRouter)
      }
    }

    return {
      mobile,
      userName,
      ...toRefs(state),
      openModal,
      editAvatar,
      action,
      avatar,
      beforeUpload,
      isNotInitPassword,
      exchange,
      cancel,
      submit,
      // 图像上上传
      pic,
      result,
      cropperRef,
      selectFile,
      getResult,

      changeAvatar,
      // rotateRight,
      // narrow,
      // enlarge,

      goBack,
      ready,
      accountType
    }
  }
})
</script>

<style lang="scss" scoped>
.personal-wrap {
  background-color: #f2f3f7;
  height: 100%;
  padding: 80px 24px 0;
  display: flex;
  flex-direction: column;
  min-width: 1300px;
  .back-btn {
    width: 80px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    padding: 0;
  }
  header {
    margin-bottom: 7px;
  }
  .info-box {
    flex: 1;
    background: #ffffff;
    width: 820px;
    margin: 66px auto 0;
    max-height: 422px;
    .avatar {
      width: 104px;
      height: 104px;
      border-radius: 50%;
      overflow: hidden;
      position: relative;
      top: -53px;
      margin: 0 auto;
      object-fit: cover;
      &:hover > .edit-text {
        display: block;
      }
      .img {
        width: 100%;
        height: 100%;
      }
      .edit-text {
        position: absolute;
        left: 0;
        bottom: 0;
        cursor: pointer;
        width: 104px;
        height: 104px;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.5);
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 104px;
        text-align: center;
        display: none;
      }
    }
    .edit-item {
      width: 54%;
      margin: 0 auto 24px;
      overflow: hidden;
      // height: 20px;
      .label {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        line-height: 20px;
        display: inline-block;
        width: 108px;
        vertical-align: top;
      }
      .content {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
        line-height: 22px;
        display: inline-block;
        width: 270px;
      }
      .text {
        color: #1890ff;
        font-size: 14px;
        float: right;
        cursor: pointer;
        width: 56px;
        line-height: 22px;
      }
      .edit-icon {
        float: right;
        height: 20px;
        cursor: pointer;
      }
    }
  }
}
.return-btn {
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 33px;

  img {
    margin-right: 5px;
    cursor: pointer;
    transform: translateY(3px);
    width: 28px;
  }
}
.avatar-content {
  .avatar-col {
    width: 302px;
    margin-right: 24px;
    .cropper {
      width: 100%;
      height: 302px;
    }
  }
  .upload-content {
    font-size: 14px;
    align-items: center;
    .icons {
      font-size: 20px;
      margin-left: 16px;
      color: #666666;
      cursor: pointer;
    }
    .select-picture {
      position: relative;
      border: none;
      box-shadow: none;
      padding: 0;
      input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
      }
    }
  }
}
.uploadbox-center {
  display: flex;
  justify-content: center;
  margin-top: -32px;
  :deep(.ant-btn) {
    color: rgba(0, 0, 0, 0.65);
  }
}

.info-content {
  padding-top: 24px;
}
</style>
